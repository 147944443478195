.fc-toolbar-title {
	text-transform: capitalize;
}

.event-item {
	padding: 10px 15px !important;
	border: none !important;
	color: white !important;
}

.fc-toolbar-chunk {
	button {
		border: none !important;
		background: #fcae44 !important;
		box-shadow: none !important;
		font-size: 0.9rem !important;

		&:hover {
			background: #f59e0b !important;
		}
	}

	.fc-today-button {
		background: #065f46 !important;
		opacity: 1 !important;

		&:hover {
			background: #064e3b !important;
		}
	}

	.fc-button-active {
		border: 3px solid #fcae44 !important;
		background: #f59e0b !important;
	}
}
