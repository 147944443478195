.alert-custom {
	position: fixed;
	border-left: 8px solid;
	border-right: none;
	border-top: none;
	border-bottom: none;
	padding: 20px 40px;
	min-width: 420px;
	max-width: 700px;
	right: 0;
	bottom: 20px;
	border-radius: 4px;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
	z-index: 10000;

	.fa-check-circle {
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 30px;
	}

	.msg {
		padding: 0 20px;
		font-size: 18px;
	}
}

.showAlert-custom {
	opacity: 1;
	pointer-events: auto;
}

.show-custom {
	animation: show_slide 1s ease forwards;
}

.hide-custom {
	animation: hide_slide 5s ease forwards;
}

@keyframes show_slide {
	0% {
		transform: translateX(100%);
	}
	40% {
		transform: translateX(-10%);
	}
	80% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-10px);
	}
}

@keyframes hide_slide {
	0% {
		transform: translateX(-10px);
	}
	40% {
		transform: translateX(0%);
	}
	80% {
		transform: translateX(-10%);
	}
	100% {
		transform: translateX(100%);
	}
}
