.list {
	max-height: 300px;
	overflow: auto;
	padding-right: 10px;
}

.list--item {
	padding: 10px;
	border-radius: 10px;
	transition: all 0.1s;
	cursor: pointer;
	position: relative;
	margin-bottom: 5px;

	.delete-user {
		position: absolute;
		transform: translate(-50%, -50%);
		top: 50%;
		right: 10px;
		display: none;
		height: 30px;
		width: 30px;
		font-size: 12px;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
		color: rgba(255, 0, 5, 1);
		background: rgba(138, 0, 5, 0.1);
		transition: all 0.1s;

		&:hover {
			transition: all 0.1s;
			background: rgba(138, 0, 5, 0.3);
		}
	}

	&:hover {
		transition: all 0.1s;
		background: rgba(0, 0, 0, 0.07);

		.delete-user {
			display: flex;
		}
	}
}

.toolbar {
	display: flex;
	align-items: center;
	padding: 5px 0 15px 0;
	margin-bottom: 5px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.08);

	&--item {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;

		i {
			height: 40px;
			width: 40px;
			border-radius: 50%;
			background: rgba(30, 58, 138, 0.2);
			display: flex;
			align-items: center;
			justify-content: center;
			color: rgba(30, 58, 255, 1);
			cursor: pointer;
			transition: all 0.1s;
		}

		&:hover {
			i {
				transition: all 0.1s;
				background: rgba(30, 58, 138, 0.3);
			}
		}
	}

	.delete {
		margin-left: 10px;
		background: rgba(138, 0, 5, 0.2);
		color: rgba(255, 0, 5, 1);
		transition: all 0.1s;

		&:hover {
			transition: all 0.1s;
			background: rgba(138, 0, 5, 0.3);
		}
	}
}

.selected-active {
	background: rgba(30, 58, 138, 0.1);
}

.div-table {
	display: flex;
	justify-content: center;
	text-align: center;
	.div-col {
		width: 100%;
	}
}

.wrapper {
	justify-content: center;
	text-align: center;
}
