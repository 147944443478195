.notification-item{
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 20px;
    height: fit-content;
    background: white;
    border-radius: 10px;
    border-top: 1px solid #07294e;
    border-right: 1px solid #07294e;
    border-bottom: 1px solid #07294e;
    border-left: 5px solid #07294e;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.1s;
}
