table{
  tr{
    text-align: center;
  }
}
@media screen and (max-width: 768px){
  table{
    width: 600px;
    thead{
      font-size: 14px;
    }
    tbody{
      td{
        padding: 10px;
        font-size: 12px;
      }
    }
  }
}
