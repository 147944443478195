@import 'assets/styles/var';
@import 'assets/styles/responsive';

.side-menu {
	width: 92px;
	min-width: 92px;
	transition: all 0.3s ease;
	padding: 20px;

	&--item {
		padding: 8px;
		height: 50px;
		min-width: 60px;
		transition: all 0.15s ease;
		position: relative;
		border-radius: 10px;

		.label {
			white-space: nowrap;
			position: absolute;
			left: 75px;
		}

		&:hover {
			transition: all 0.15s ease;
			background: rgba(0, 0, 0, 0.06);
		}

		&:active {
			transition: all 0.15s ease;
			background: rgba(0, 0, 0, 0.08);
		}

		.icon {
			min-width: 40px;
		}

		.label {
			display: none;
		}
	}
}

.active-link {
	.active {
		background: $color-jacksons-purple;
		color: $white;
	}
}

.side-menu--full {
	@include media-breakpoint-up(lg) {
		width: 300px;
		transition: all 0.3s ease;

		.label {
			display: block;
		}
	}
}
