@import 'assets/styles/responsive';

.layout {
	&__side-menu {
		top: 70px;
		z-index: 9;
	}

	&__nav-bar {
		z-index: 10;
		top: 0;
	}

	&__content {
		margin-top: 90px;
		transition: all 0.3s ease;
		width: calc(100% - 112px);
		margin-left: 112px;
	}

	.full-menu {
		@include media-breakpoint-up(lg) {
			transition: all 0.3s ease;
			width: calc(100% - 320px);
			margin-left: 320px;
		}
	}
}
