// color
$color-sapphire: #07294d;
$color-my-sin: #fcae44;
$color-jacksons-purple: #1e3a8a;
$white: white;

// class
.color-sapphire {
	color: $color-sapphire;
}

.color-my-sin {
	color: $color-my-sin;
}

.bg-sapphire {
	background: $color-sapphire;
}

.bg-my-sin {
	background: $color-my-sin;
}

// size

.max-450 {
	max-width: 450px;
	margin: 0 auto;
	display: block;
}

.h-fit {
	height: fit-content;
}

.w-fit {
	width: fit-content;
}

// input custom

.input-custom {
	width: 400px;

	&::placeholder {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}
}


// icon

.d-icon {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	opacity: 0.7;
	transition: all 0.15s ease;
	background: rgba(0, 0, 0, 0);

	&:hover {
		transition: all 0.15s ease;
		opacity: 1;
	}

	&:active {
		transition: all 0.15s ease;
		background: rgba(0, 0, 0, 0.05);
	}
}

// scroll
.w-auto-scroll {
	overflow-x: auto;
	/* width */
	&::-webkit-scrollbar {
		height: 7px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0.1);
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: rgba(0, 0, 0, 0.3);
	}
}

//space

.space {
	height: 5px;
	width: 5px;
}
