@import '../../../assets/styles/var';

.subject {
	&--list {
		padding: 30px 0;
	}
	.fc-timeGridWeek-button{
		display: none !important;
	}
	.fc-dayGridMonth-button{
		display: none !important;
	}
	.event-item{
		font-size: 11.5px;
		word-wrap: break-word;      /* IE 5.5-7 */
		white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
		white-space: pre-wrap;      /* current browsers */
	}
	.subject-detail{
		h2{
			color: red;
			font-weight: bold;
			font-size: 20px;
			text-align: center;
			text-transform: uppercase;
			padding: 20px 0;
		}
	}
	&--item {
		height: 100px;
		width: 200px;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		background: url('images/fucalties_background.jpg') no-repeat center
			center;
		background-size: cover;

		&:after {
			content: '';
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.4);
			z-index: 1;
		}

		.name {
			position: relative;
			z-index: 2;
		}

		.actions {
			transition: all 0.4s ease;
			position: absolute;
			bottom: 10px;
			transform: translateY(50px);
			z-index: 2;

			&--item {
				height: 30px;
				width: 30px;
				border-radius: 50%;
				background: rgba(255, 255, 2550, 0.1);
				font-size: 0.8rem;
				display: flex;
				align-items: center;
				justify-content: center;

				&:hover {
					background: rgba(255, 255, 255, 0.3);
				}
			}
		}

		&:hover {
			.actions {
				transition: all 0.4s ease;
				transform: translateY(0);
			}
		}
	}

	li>ul                 { transform: translatex(100%) scale(0) }
	li:hover>ul           { transform: translatex(101%) scale(1) }
	li > button svg       { transform: rotate(-90deg) }
	li:hover > button svg { transform: rotate(-270deg) }

	/* Below styles fake what can be achieved with the tailwind config
       you need to add the group-hover variant to scale and define your custom
       min width style.
         See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
         for implementation with config file
    */
	.group:hover .group-hover\:scale-100 { transform: scale(1) }
	.group:hover .group-hover\:-rotate-180 { transform: rotate(180deg) }
	.scale-0 { transform: scale(0) }
	.min-w-32 { min-width: 8rem }
	.min-w-64 { min-width: 16rem }
}
