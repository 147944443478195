@import './src/assets/styles/var';

.faculties {
	&--list {
		padding: 30px 0;
	}
	.fc-timeGridWeek-button{
		display: none !important;
	}
	.fc-dayGridMonth-button{
		display: none !important;
	}
	.event-item{
		font-size: 11.5px;
		word-wrap: break-word;      /* IE 5.5-7 */
		white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
		white-space: pre-wrap;      /* current browsers */
	}
	&--item {
		height: 100px;
		width: 200px;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		background-color: #1e3a8a;
		.name {
			position: relative;
			z-index: 2;
			text-align: center;
		}

		.actions {
			transition: all 0.4s ease;
			position: absolute;
			bottom: 10px;
			transform: translateY(50px);
			z-index: 2;

			&--item {
				height: 30px;
				width: 30px;
				border-radius: 50%;
				background: rgba(255, 255, 2550, 0.1);
				font-size: 0.8rem;
				display: flex;
				align-items: center;
				justify-content: center;

				&:hover {
					background: rgba(255, 255, 255, 0.3);
				}
			}
		}

		&:hover {
			.actions {
				transition: all 0.4s ease;
				transform: translateY(0);
			}
		}

		
	}
}