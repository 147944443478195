@import 'react-datepicker/dist/react-datepicker.css';
@import '_var';

:root {
	--primary-color: #1e3a8a;
}

html {
	scroll-behavior: smooth;
	padding-right: 17px;
	/* width */
	::-webkit-scrollbar {
		width: 7px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0.1);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: rgba(0, 0, 0, 0.3);
	}
}

body {
	font-size: 16px;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
	background: rgba(0, 0, 0, 0.05);
	min-width: 750px;
}

.page {
	margin-bottom: 100px;

	&__content {
		padding: 20px;
		border-radius: 10px;
	}
}

.css-yk16xz-control {
	border: 1px solid #d1d5db !important;
	border-radius: 6px !important;
}

.css-g1d714-ValueContainer,
.css-b8ldur-Input {
	height: 42px;

	input {
		height: 42px !important;
		padding: 0 !important;
	}
}

.css-1wa3eu0-placeholder {
	height: 40px;
	display: none;
}

.css-1uccc91-singleValue {
	transform: translateY(-6px) !important;
}

.__version {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: fixed;
	padding: 20px 30px;
	border-radius: 6px;
	text-align: center;

	.name {
		font-weight: bolder;
	}
}

.___title {
	font-weight: bold;
	border-radius: 3px;
	color: $color-sapphire;

	&__decorate {
		content: '';
		height: 20px;
		width: 3px;
		background: $color-sapphire;
		margin-right: 10px;
		border-radius: 0 4px;
	}

	&__box {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;

		&__last {
			margin: 0 10px;
		}
	}
}
