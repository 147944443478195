.auth {
	background: #0575e6; /* fallback for old browsers */
	background: -webkit-linear-gradient(
		to right,
		#021b79,
		#0575e6
	); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#021b79,
		#0575e6
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
}

.login-dialog {
	transform: translate(-50%, -50%);
	width: 90%;
	height: fit-content;
	max-width: 390px;
	position: fixed;
	top: 50%;
	left: 50%;
}
