.year-schedule {
	overflow-x: auto;
	padding-bottom: 50px;
	/* width */
	&::-webkit-scrollbar {
		height: 7px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0.1);
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: rgba(0, 0, 0, 0.3);
	}

	.schedule-grid {
		display: flex;
		flex-wrap: nowrap;
	}

	&__content {
		width: fit-content;
	}

	.schedule-progress-bar {
		border-left: 5px solid #1e3a8a;
		min-height: 50px;
		display: flex;
		align-items: center;
		padding: 0.5rem 0 0.5rem 0.5rem;
		background: rgba(30, 58, 138, 0.2);
		border-radius: 2px 10px 10px 2px;
		color: rgba(30, 58, 138, 1);
		font-weight: bolder;
		cursor: pointer;
		font-size: 0.85rem;
		position: relative;

		.delete {
			position: absolute;
			top: 50%;
			right: 0;
			font-size: 1.5rem;
			display: none;
			transform: translate(50%, -50%);
		}

		&:hover {
			.delete {
				display: block;
			}
		}
	}

	.c-header {
		margin-bottom: 10px;

		&--item {
			width: 100px;
			padding: 0.75rem;
			text-align: center;

			&:not(:first-child) {
				border-left: 1px solid rgba(0, 0, 0, 0.1);
			}
		}
	}

	.c-content {
		width: 100%;

		&--item {
			padding: 10px 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.07);
			transition: all 0.2s ease;

			&_child {
				overflow: hidden;
				width: 100%;
			}

			&:hover {
				background: rgba(0, 0, 0, 0.05);
				transition: all 0.1s ease;
			}
		}
	}
}
