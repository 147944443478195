.login {
	padding: 70px 40px 40px;

	&__logo {
		transform: translateY(-50%);
		top: 0;

		.img-logo {
			height: 100px;
			width: 100px;
		}
	}
}
