
.table-exam{
  td{
    padding: 3px !important;
  }
  th{
    padding: 3px !important;
  }
  input:not([type='checkbox']) {
    max-width: 80px;
  }
  .bg-yellow{
    background-color: yellow;
  }
  .bg-gray{
    background-color: grey;
  }
}